// import React, { useContext, useEffect, useState } from "react";
// // import Img from "../img/img.png";
// // import Attach from "../img/attach.png";
// import {
//   arrayUnion,
//   doc,
//   serverTimestamp,
//   Timestamp,
//   updateDoc,
// } from "firebase/firestore";
// import { db, storage } from "../../Utility/firebase";
// import { v4 as uuid } from "uuid";
// import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
// import { AuthContext } from "../../Context/AuthContext";
// import { ChatContext } from "../../Context/ChatContext";
// import { set } from "firebase/database";

// const ChatInput = ({ setLoading }) => {
//   const [text, setText] = useState("");
//   const [img, setImg] = useState(null);
//   const [isTyping, setIsTyping] = useState(false);

//   const { currentUser } = useContext(AuthContext);
//   const { data } = useContext(ChatContext);

//   const handleSend = async () => {
//     if (img) {
//       const storageRef = ref(storage, uuid());
//       setLoading(true);
//       const uploadTask = uploadBytesResumable(storageRef, img);

//       uploadTask.on(
//         (error) => {
//           //TODO:Handle Error
//         },
//         () => {
//           getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
//             await updateDoc(doc(db, "chats", data?.chatId), {
//               messages: arrayUnion({
//                 id: uuid(),
//                 text,
//                 senderId: currentUser?.uid,
//                 date: Timestamp.now(),
//                 img: downloadURL,
//               }),
//             });
//           });
//         }
//       );
//     } else {

//       await updateDoc(doc(db, "chats", data?.chatId), {
//         messages: arrayUnion({
//           id: uuid(),
//           text,
//           senderId: currentUser?.uid,
//           date: Timestamp.now(),
//         }),
//       });
//     }

//     await updateDoc(doc(db, "userChats", currentUser?.uid), {
//       [data?.chatId + ".lastMessage"]: {
//         text,
//       },
//       [data?.chatId + ".date"]: serverTimestamp(),
//     });

//     await updateDoc(doc(db, "userChats", data?.user?.uid), {
//       [data?.chatId + ".lastMessage"]: {
//         text,
//       },
//       [data?.chatId + ".date"]: serverTimestamp(),
//     });
//     setLoading(false);
//     setText("");
//     setImg(null);
//   };

//   return (
//     <div className="input">
//       <input
//         type="text"
//         placeholder="Type something..."
//         onChange={(e) => {setText(e.target.value)

//         }}
//         value={text}
//       />
//       <div className="send">
//         {/* <img src={Attach} alt="" /> */}
//         <input
//           type="file"
//           style={{ display: "none" }}
//           id="file"
//           onChange={(e) => setImg(e.target.files[0])}
//         />
//         <label htmlFor="file">{/* <img src={Img} alt="" /> */}</label>
//         <button onClick={handleSend}>Send</button>
//       </div>
//     </div>
//   );
// };

// export default ChatInput;

import React, { useContext, useEffect, useState } from 'react'
import {
  arrayUnion,
  deleteField,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  increment,
} from 'firebase/firestore'
import { db } from '../../Utility/firebase'
import { v4 as uuid } from 'uuid'
import { AuthContext } from '../../Context/AuthContext'
import { ChatContext } from '../../Context/ChatContext'
import { getData, storageKey } from '../../Utility/Storage'
import { sendMessage } from '../../Redux/Services/OtherServices'
import { useDispatch } from 'react-redux'

const ChatInput = ({ setLoading }) => {
  const [text, setText] = useState('')
  const [img, setImg] = useState(null)
  const [isTyping, setIsTyping] = useState(false)
  const dispatching = useDispatch()
  const { currentUser } = useContext(AuthContext)
  const { data } = useContext(ChatContext)

  const updateTypingStatus = async (chatId, isTyping) => {
    try {
      const otherUserId = data?.user?.uid
      const typingStatusRef = doc(
        db,
        'typingStatus',
        `${otherUserId}_${currentUser?.uid}`,
      )

      await setDoc(typingStatusRef, {
        typing: isTyping,
      })
    } catch (error) {
      console.error('Error updating typing status:', error)
    }
  }

  // const handleSend = async () => {
  //   setLoading(true);
  //   const chatId = data?.chatId;
  //   const recipientId = data?.user?.uid;
  //   const chatDocRef = doc(db, "chats", chatId);
  //   const chatDoc = await getDoc(chatDocRef);
  //   console.log(chatDoc.exists(),"klhgjkljj");

  //   // if (!chatDoc.exists()) {
  //   //   // If the chat document does not exist, create a new one
  //   //   await setDoc(chatDocRef, {
  //   //     messages: [], // You can initialize with an empty messages array
  //   //     createdAt: serverTimestamp(),
  //   //   });
  //   //   console.log("New chat document created");
  //   // }

  //   const userChatRef = doc(db, "userChats", recipientId);
  //   await updateDoc(userChatRef, {
  //     [`${chatId}.deletedAt`]: deleteField(),
  //   });

  //   await updateDoc(doc(db, "chats", data?.chatId), {
  //     messages: arrayUnion({
  //       id: uuid(),
  //       text,
  //       senderId: currentUser?.uid,
  //       date: Timestamp.now(),
  //       receiverId: data?.user?.uid,
  //       status: "sent",
  //     }),
  //   });

  //   await updateDoc(doc(db, "userChats", currentUser?.uid), {
  //     [data?.chatId + ".lastMessage"]: {
  //       text,
  //     },
  //     [data?.chatId + ".date"]: serverTimestamp(),
  //   });

  //   await updateDoc(doc(db, "userChats", data?.user?.uid), {
  //     [data?.chatId + ".lastMessage"]: {
  //       text,
  //     },
  //     [data?.chatId + ".date"]: serverTimestamp(),
  //   });
  //   // [data?.chatId + ".userInfo"]: {
  //   //   uid: currentUser?.uid,
  //   //   displayName: currentUser?.displayName,
  //   //   photoURL: currentUser?.photoURL,
  //   // },
  //   setLoading(false);
  //   setText("");
  //   updateTypingStatus(data?.chatId, false);
  // };


  const handleSend = async () => {
    // setLoading(true)
    const user_id = await getData(storageKey?.USER_ID)
    const chatId = data?.chatId // The chat's ID
    const recipientId = data?.user?.uid // User A's UID
    const chatDocRef = doc(db, 'chats', chatId)
    const combinedId =
      currentUser.uid > recipientId
        ? currentUser.uid + recipientId
        : recipientId + currentUser.uid

    const chatDoc = await getDoc(chatDocRef)
    const userChatRef = doc(db, 'userChats', recipientId)

    const chatData = chatDoc.data()
    if ('userInfo' in chatData || 'user' in chatData) {
      await updateDoc(doc(db, 'userChats', currentUser?.uid), {
        [data?.chatId + '.lastMessage']: {
          text,
        },
        [data?.chatId + '.date']: Timestamp.now(),
      })

      await updateDoc(doc(db, 'userChats', data?.user?.uid), {
        [data?.chatId + '.lastMessage']: {
          text,
        },
        [data?.chatId + '.date']: Timestamp.now(),
        [data?.chatId + '.unreadCount']: increment(1),
      })
    } else {
      await updateDoc(doc(db, 'userChats', currentUser?.uid), {
        [data?.chatId + '.lastMessage']: {
          text,
        },
        [data?.chatId + '.date']: Timestamp.now(),

        [data?.chatId + '.userInfo']: {
          uid: data?.user?.uid,
          displayName: data?.user?.displayName,
          photoURL: data?.user?.photoURL,
          user_id: data?.user?.user_id,
        },
      })

      await updateDoc(doc(db, 'userChats', data?.user?.uid), {
        [data?.chatId + '.lastMessage']: {
          text,
        },
        [data?.chatId + '.date']: Timestamp.now(),
        [data?.chatId + '.unreadCount']: increment(1),
        [data?.chatId + '.userInfo']: {
          uid: currentUser?.uid,
          displayName: currentUser?.displayName,
          photoURL: currentUser?.photoURL,
          user_id: user_id,
        },
      })
    }
    await updateDoc(userChatRef, {
      [`${chatId}.deletedAt`]: deleteField(),
    })
    await updateDoc(doc(db, 'chats', data?.chatId), {
      messages: arrayUnion({
        _id: uuid(),
        text,
        senderId: currentUser?.uid,
        date: Timestamp.now(),
        receiverId: data?.user?.uid,
        status: 'sent',
        user: {
          _id: user_id,
          name: currentUser?.displayName,
          avatar: currentUser?.photoURL,
        },
      }),
    })
    setText('')
    let uploadData = new FormData()
    uploadData?.append('sender_id', user_id)
    uploadData?.append('reciver_id', data?.user?.user_id)
    uploadData?.append('message', text)
    let res = await dispatching(sendMessage(uploadData))

    // If only text is sent

    // Update the last message for both users

    // [data?.chatId + ".userInfo"]: {
    //   uid: currentUser?.uid,
    //   displayName: currentUser?.displayName,
    //   photoURL: currentUser?.photoURL,
    // },
    setLoading(false)

    updateTypingStatus(data?.chatId, false)

    // await updateDoc(chatDocRef, {
    //   unreadCount: increment(1),
    // })
  }

  useEffect(() => {
    let typingTimeout
    if (isTyping) {
      updateTypingStatus(data?.chatId, true)
      typingTimeout = setTimeout(() => {
        setIsTyping(false)
        updateTypingStatus(data?.chatId, false)
      }, 3000)
    }

    return () => {
      clearTimeout(typingTimeout)
    }
  }, [isTyping, data?.chatId])

  return (
    <div className="input">
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => {
          setText(e.target.value)
          setIsTyping(true)
        }}
        onKeyDown={(e) =>
          e.code === 'Enter' && text?.length != 0 ? handleSend() : null
        }
        value={text}
      />
      <div className="send">
        <input
          type="file"
          style={{ display: 'none' }}
          id="file"
          onChange={(e) => setImg(e.target.files[0])}
        />
        <label htmlFor="file"></label>
        <button onClick={text?.length != 0 ? handleSend : null}>Send</button>
      </div>
    </div>
  )
}

export default ChatInput
