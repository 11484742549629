import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Redux/Store/configureStore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContextProvider } from "./Context/AuthContext";
import { ChatContextProvider } from "./Context/ChatContext";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_LIVE_KEY } from "./Utility";
const root = ReactDOM.createRoot(document.getElementById("root"));
const stripePromise = loadStripe(STRIPE_LIVE_KEY);

root.render(
  <Provider store={store}>
    <AuthContextProvider>
      <ChatContextProvider>
        <React.StrictMode>
          <Elements stripe={stripePromise}>
            <ToastContainer autoClose={2000} />
            <App />
          </Elements>
        </React.StrictMode>
      </ChatContextProvider>
    </AuthContextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
