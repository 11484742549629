import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
export const LightBox = (props) => {
  const { toggler, setToggler, images } = props;
  return (
    <>
      <FsLightbox toggler={toggler} sources={images} />
    </>
  );
};
