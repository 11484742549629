import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { showToast, STRIPE_LIVE_KEY } from "../../Utility";
import { Colors } from "../../Constants";
import { buyPackage } from "../../Redux/Services/OtherServices";
import { useDispatch } from "react-redux";
import { getData, storageKey } from "../../Utility/Storage";
import { Loader } from "../Loader";

const stripePromise = loadStripe(STRIPE_LIVE_KEY);

export const StripeCardModal = (props) => {
  const { open, setOpen, pacData, profileSubmit } = props;
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [cardType, setCardType] = useState(""); // For storing detected card type

  const handleCardChange = (event) => {
    if (event.brand) {
      // Update card type when detected
      setCardType(event.brand === "unknown" ? "" : event.brand);
    }
  };

  const handleShow = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      showToast("Stripe.js has not loaded yet.", "error");
      setLoading(false);
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });
    console.log("paymentMethod------", paymentMethod);

    if (error) {
      showToast(error.message, "error");
    } else {
      let userID = await getData(storageKey?.USER_ID);

      // showToast(
      //   `Payment method created successfully: ${paymentMethod.id}`,
      //   "success"
      // );
      let body = {
        action: "payment_hold",
        amount: pacData?._price,
        currency: "usd",
        payment_method_id: paymentMethod.id,
        product_id: pacData?.custom_product_id,
        price_id: pacData?.custom_price_id,
        user_id: userID ? JSON?.parse(userID) : "",
      };
      console.log("bodybody-----", body);
      let res = await dispatch(buyPackage(body));
      console.log("buyPackage response-----", res);
      const status = res?.results?.status || res?.results?.paymentIntent.status;
      console.log("res?.results?.status----", status);

      // if (res?.status == 200) {
      //   // profileSubmit();
      // if (status == "requires_capture") {
      //   console.log("Payment authorized but not captured.");
      // } else if (status == "requires_action") {
      if (paymentMethod?.id) {
        const clientSecret =
          res?.results?.client_secret ||
          res?.results?.paymentIntent?.client_secret;
        console.log("clientSecret------", clientSecret);
        const cardElement = elements.getElement(CardNumberElement);

        const { error, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
        setOpen(false);
        if (error) {
          console.error("Error during confirmation:", error.message);
        } else {
          profileSubmit();
          console.log("PaymentIntent confirmed:", paymentIntent);
        }
        // }
      }

      // if (err) {
      //   showToast(`Payment failed: ${err.message}`, "error");
      //   console.log("paymentIntent-----", err);
      // } else if (paymentIntentRes.status === "succeeded") {
      //   console.log("paymentIntentRes-----", paymentIntentRes);
      //   showToast("Payment succeeded!", "success");
      // } else {
      //   showToast("Payment failed. Please try again.", "error");
    }
    // }
    // showToast("Your package has been purchased", "success");
    // showToast("Please wait for your application approval", "info");
    // }
    // Send paymentMethod.id to your server for processing
    // }
    setLoading(false);
  };

  const elementOptions = {
    style: {
      base: {
        color: Colors?.darkgrey,
        fontSize: "16px",
        fontFamily: "Arial, sans-serif",
        "::placeholder": {
          color: Colors?.darkgrey,
        },
      },
      invalid: {
        color: Colors?.red,
        iconColor: Colors?.red,
      },
    },
  };
  const cardIcons = {
    visa: "https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png",
    mastercard:
      "https://upload.wikimedia.org/wikipedia/commons/b/b7/MasterCard_Logo.svg",
    amex:
      "https://www.pngitem.com/pimgs/m/179-1792533_credit-card-clipart-american-express-amex-logo-png.png",
    discover:
      "https://imageio.forbes.com/specials-images/imageserve/34366558/DISCOVER-LOGO/960x0.jpg?format=jpg&width=960",
    unknown: "",
  };

  return (
    <>
      <Loader loading={loading} />
      <Modal show={open} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <div
                className="card-label"
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  padding: "10px",
                }}
              >
                {/* Card Number Input */}
                <div style={{ flex: 1 }}>
                  <CardNumberElement
                    options={elementOptions}
                    onChange={handleCardChange}
                  />
                </div>
                <div style={{ marginRight: "10px" }}>
                  {cardType && (
                    <img
                      src={cardIcons[cardType] || cardIcons["unknown"]}
                      alt={cardType}
                      style={{
                        width: "35px",
                        height: "20px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <div className="card-label">
                <CardExpiryElement options={elementOptions} />
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <div className="card-label">
                <CardCvcElement options={elementOptions} />
              </div>
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              className="w-100"
              disabled={!stripe || loading}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Processing...
                </>
              ) : (
                "Pay Now"
              )}
            </Button>
            {loading && (
              <small style={{ textAlign: "center" }}>
                Please do not refresh the page while processing!
              </small>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
