import { doc, onSnapshot, updateDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { db } from '../../Utility/firebase'
import Message from './Message'
import { ChatContext } from '../../Context/ChatContext'
import { AuthContext } from '../../Context/AuthContext'

const Messages = ({ setLoading }) => {
  const [messages, setMessages] = useState([])
  const [userDeletedMessages, setUserDeletedMessages] = useState([])
  const { data } = useContext(ChatContext)
  const { currentUser } = useContext(AuthContext)

  useEffect(() => {
    if (!data?.chatId || !currentUser?.uid) return
    setLoading(true)
    const unSub = onSnapshot(doc(db, 'chats', data?.chatId), (doc) => {
      if (doc.exists()) {
        let msgArr = doc.data().messages
        let filteredMsg = msgArr.filter(
          (msg) => !msg?.deletedFor?.includes(currentUser.uid),
        )
        setMessages(filteredMsg)
      }
    })
    // const messageStatus = onSnapshot(db, 'chats', data?.chatId, (chatDoc) => {
    //   const messages = chatDoc.data()?.messages || []
    //   messages.forEach(async (message) => {
    //     if (
    //       message.status === 'sent' &&
    //       message?.receiverId === currentUser?.uid
    //     ) {
    //       const messageIndex = messages.findIndex((m) => m._id === message._id)
    //       messages[messageIndex].status = 'read'
    //       await updateDoc(db, 'chats', data?.chatId, { messages })
    //     }
    //   })
    // })

    // Fetch the deleted messages for the current user
    const userRef = doc(db, 'users', currentUser.uid)
    const unSubUser = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setUserDeletedMessages(doc.data().deletedMessages || [])
      }
    })

    setLoading(false)

    return () => {
      unSub()
      unSubUser()
      // messageStatus()
    }
  }, [data?.chatId, currentUser.uid, setLoading])

  // Function to filter out messages that have been deleted for the current user
  const renderMessages = (messages) => {
    return messages
      .filter((message) => !userDeletedMessages.includes(message._id))

      .map((m) => <Message message={m} key={m._id} />)
  }

  return (
    <div className="messages">
      {messages?.length ? renderMessages(messages) : undefined}
    </div>
  )
}

export default Messages
