import React, { useContext, useEffect, useState } from 'react'
import {
  doc,
  onSnapshot,
  updateDoc,
  deleteDoc,
  deleteField,
  serverTimestamp,
  collection,
  getDoc,
  setDoc,
  getDocs,
  arrayUnion,
  writeBatch,
  query,
  where,
} from 'firebase/firestore'

import { AuthContext } from '../../Context/AuthContext'
import { ChatContext } from '../../Context/ChatContext'
import { db, firebaseDB } from '../../Utility/firebase'
import { useLocation } from 'react-router-dom'
import { DeletePost } from '../../Components'
import UserStatus from './UserStatus'
const Chats = ({ setLoading }) => {
  const [chats, setChats] = useState([])
  const [typingStatus, setTypingStatus] = useState({})
  const [isDelete, setIsDelete] = useState(false)
  const [chatId, setChatId] = useState('') // To store the chat ID that we want to delete
  // const firestoreDB = db.firestore();
  const location = useLocation()

  const queryParams = new URLSearchParams(location?.search)
  const displayName = queryParams?.get('displayName')
  const uid = queryParams?.get('uid')
  const photoURL = queryParams?.get('photoURL')
  const user_id = queryParams?.get('user_id')

  const { currentUser } = useContext(AuthContext)
  const { dispatch, data } = useContext(ChatContext)

  useEffect(() => {
    if (currentUser?.uid) getChats()
  }, [currentUser?.uid])
  const getChats = () => {
    const unsub = onSnapshot(
      doc(db, 'userChats', currentUser?.uid),
      async (docs) => {
        const chatData = docs.data()
        setChats(chatData)

        if (chatData) {
          Object.entries(chatData).forEach(async ([chatId, chat]) => {
            const chatRef = doc(db, 'chats', chatId)
            const typingStatusRef = doc(
              db,
              'typingStatus',
              `${currentUser?.uid}_${chat?.userInfo?.uid}`,
            )

            // Listen for new messages and update the delivery status
            // const messageStatus = onSnapshot(chatRef, (chatDoc) => {
            //   const messages = chatDoc.data()?.messages || []
            //   messages.forEach(async (message) => {
            //     console.log(
            //       'message?.receiverId === currentUser?.uid-----',
            //       message?.receiverId,
            //       currentUser?.uid,
            //     )
            //     if (
            //       message.status == 'sent' &&
            //       message?.receiverId === currentUser?.uid
            //     ) {
            //       console.log(
            //         'message?.receiverId === ',
            //         message?.receiverId === currentUser?.uid,
            //       )
            //       // Update message status to "delivered"
            //       const messageIndex = messages.findIndex(
            //         (m) => m._id === message._id,
            //       )
            //       messages[messageIndex].status = 'read'
            //       await updateDoc(chatRef, { messages })
            //     }
            //   })
            // })

            // Track typing status
            onSnapshot(typingStatusRef, (typingDoc) => {
              if (typingDoc.exists()) {
                setTypingStatus((prevStatus) => ({
                  ...prevStatus,
                  [chat?.userInfo?.uid]: typingDoc.data()?.typing,
                }))
              }
            })
          })
        }
      },
    )
    return () => unsub()
  }

  const handleSelect = (u) => {
    dispatch({ type: 'CHANGE_USER', payload: u })
  }

  const handleDeleteClick = async (chat) => {
    setChatId(chat)
    setIsDelete(true) // Show confirmation modal
  }

  const handleDeleteChat = async () => {
    let chatID = chatId[0]
    const userChatsRef = doc(db, 'userChats', currentUser.uid)
    const chatDocRef = doc(db, 'chats', chatID)

    try {
      setLoading(true)
      const chatDoc = await getDoc(chatDocRef)
      const chatData = chatDoc.data()
      const messages = chatData?.messages || []
      const updatedMessages = messages.map((message) => {
        return {
          ...message,
          deletedFor: message.deletedFor
            ? [...message.deletedFor, currentUser.uid]
            : [currentUser.uid],
        }
      })
      await updateDoc(chatDocRef, {
        messages: updatedMessages,
      })
      //old
      await updateDoc(userChatsRef, {
        // [chatId?.[1]?.userInfo?.uid]: deleteField(),
        [chatID]: deleteField(),
      })

      console.log('Chat with user', chatID, 'successfully deleted!')
      const updatedChatsDoc = await getDoc(userChatsRef)
      if (updatedChatsDoc.exists()) {
        const updatedChats = updatedChatsDoc.data()
        setChats(updatedChats)
        dispatch({ type: 'SWITCH_USER_NULL', payload: null })
      } else {
        setChats([])
      }
      setLoading(false)
    } catch (error) {
      console.error('Error removing chat:', error)
    }
  }

  // const handleDelete = async () => {
  //   try {
  //     setLoading(true);

  //     // Clear the chat's last message field without deleting the user info
  //     const userChatRef = doc(db, "userChats", currentUser?.uid);
  //     await updateDoc(userChatRef, {
  //       [`${chatId}.lastMessage`]: deleteField(),  // Remove the last message
  //       [`${chatId}.date`]: deleteField(),        // Optionally remove date
  //     });

  //     // Optionally, you could add a flag like "isDeleted"
  //     // await updateDoc(userChatRef, {
  //     //   [`${chatId}.isDeleted`]: true,
  //     // });

  //     // Close the modal and reset the state
  //     setIsDelete(false);
  //     setLoading(false);
  //     setChats((prevChats) => {
  //       const updatedChats = { ...prevChats };
  //       if (updatedChats[chatId]) {
  //         updatedChats[chatId].lastMessage = null; // Update local state accordingly
  //       }
  //       return updatedChats;
  //     });
  //   } catch (error) {
  //     console.error("Error deleting chat: ", error);
  //     setLoading(false);
  //   }
  // };

  const handleDelete = async () => {
    try {
      setLoading(true)
      // Store the timestamp of when the chat was deleted
      const userChatRef = doc(db, 'userChats', currentUser?.uid)
      await updateDoc(userChatRef, {
        [`${chatId}.lastMessage`]: deleteField(), // Remove the last message
        [`${chatId}.date`]: deleteField(), // Optionally remove date
        [`${chatId}.deletedAt`]: serverTimestamp(), // Store the deletion timestamp
      })

      // Close the modal and reset the state
      setIsDelete(false)
      setLoading(false)
      setChats((prevChats) => {
        const updatedChats = { ...prevChats }
        if (updatedChats[chatId]) {
          updatedChats[chatId].lastMessage = null
          updatedChats[chatId].deletedAt = Date.now() // Update local state with deletion timestamp
        }
        return updatedChats
      })
    } catch (error) {
      console.error('Error deleting chat: ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (displayName) {
      handleSelect({
        displayName,
        uid,
        photoURL,
        user_id,
      })
    }
  }, [displayName])

  return (
    <div className="chats">
      {chats
        ? Object.entries(chats)
            ?.sort((a, b) => b[1].date - a[1].date)
            .map((chat) => {
              const userInfo = chat[1]?.userInfo
              const isTyping = typingStatus[userInfo?.uid] // Check typing status
              if (!chat[1]?.lastMessage) {
                return null // Skip if there's no last message (chat was "deleted")
              }
              return (
                <div
                  className={
                    chat?.[1]?.userInfo?.uid === data?.user?.uid
                      ? 'userChat active'
                      : 'userChat'
                  }
                  key={chat[0]}
                  onClick={() => handleSelect(chat?.[1]?.userInfo)}
                >
                  {chat?.[1]?.lastMessage?.text ? (
                    <UserStatus userId={userInfo?.uid} />
                  ) : undefined}
                  <img src={userInfo?.photoURL} alt="" />
                  <div className="userChatInfo">
                    <span style={{ color: 'black' }}>
                      {userInfo?.displayName || ''}
                    </span>
                    <p>
                      {isTyping ? 'typing...' : chat?.[1]?.lastMessage?.text}
                    </p>
                  </div>
                  <div className="chat_options">
                    <i
                      onClick={() => handleDeleteClick(chat)}
                      className="fa fa-trash"
                      aria-hidden="true"
                    ></i>
                    {chat?.[1]?.userInfo?.uid != data?.user?.uid &&
                    chat?.[1]?.unreadCount > 0 ? (
                      <div className="unread-badge">
                        {chat?.[1]?.unreadCount}
                      </div>
                    ) : null}
                  </div>
                </div>
              )
            })
        : undefined}

      {isDelete ? (
        <DeletePost
          setShow={setIsDelete}
          show={isDelete}
          title="Delete Chat"
          handleConfirm={() => handleDeleteChat()}
          text="Are you sure you want to delete the chat with this user?"
        />
      ) : undefined}
    </div>
  )
}

export default Chats
