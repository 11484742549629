import { useContext, useEffect } from "react";
import { doc, setDoc, serverTimestamp, onSnapshot } from "firebase/firestore";

import { AuthContext } from "../../Context/AuthContext";
import { db } from "../../Utility/firebase";

const useUserStatus = () => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser) return;

    const userStatusDocRef = doc(db, "userStatus", currentUser.uid);

    const isOfflineForFirestore = {
      state: "offline",
      last_changed: serverTimestamp(),
    };

    const isOnlineForFirestore = {
      state: "online",
      last_changed: serverTimestamp(),
    };

    const isAwayForFirestore = {
      state: "away",
      last_changed: serverTimestamp(),
    };

    // Set initial status to online when user is active
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        setDoc(userStatusDocRef, isAwayForFirestore, { merge: true });
      } else {
        setDoc(userStatusDocRef, isOnlineForFirestore, { merge: true });
      }
    };

    // Listen for user connectivity status changes
    const unsubscribe = onSnapshot(
      doc(db, "userStatus", currentUser.uid),
      (docSnap) => {
        if (!docSnap.exists()) {
          setDoc(userStatusDocRef, isOnlineForFirestore); // Set to online initially
        }
      }
    );

    // Use Firestore's offline/online tracking logic
    window.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      unsubscribe(); // Stop listening for status changes
      setDoc(userStatusDocRef, isOfflineForFirestore, { merge: true }); // Set status to offline
    };
  }, [currentUser]);
};

export default useUserStatus;

// import { useContext, useEffect } from "react";
// import {
//   getDatabase,
//   ref,
//   onDisconnect,
//   set,
//   onValue,
//   serverTimestamp,
// } from "firebase/database";
// import { AuthContext } from "../../Context/AuthContext";

// const useUserStatus = () => {
//   const database = getDatabase();
//   const { currentUser } = useContext(AuthContext);

//   useEffect(() => {
//     if (!currentUser) return;

//     const userStatusDatabaseRef = ref(database, `/status/${currentUser.uid}`);

//     const isOfflineForDatabase = {
//       state: "offline",
//       last_changed: serverTimestamp(),
//     };

//     const isOnlineForDatabase = {
//       state: "online",
//       last_changed: serverTimestamp(),
//     };

//     const isAwayForDatabase = {
//       state: "away",
//       last_changed: serverTimestamp(),
//     };

//     const handleVisibilityChange = () => {
//       if (document.visibilityState === "hidden") {
//         set(userStatusDatabaseRef, isAwayForDatabase);
//       } else {
//         set(userStatusDatabaseRef, isOnlineForDatabase);
//       }
//     };

//     onValue(ref(database, ".info/connected"), (snapshot) => {
//       if (snapshot.val() === false) {
//         return;
//       }

//       onDisconnect(userStatusDatabaseRef)
//         .set(isOfflineForDatabase)
//         .then(() => {
//           set(userStatusDatabaseRef, isOnlineForDatabase);
//         });
//     });

//     window.addEventListener("visibilitychange", handleVisibilityChange);

//     return () => {
//       window.removeEventListener("visibilitychange", handleVisibilityChange);
//     };
//   }, [currentUser, database]);
// };

// export default useUserStatus;
