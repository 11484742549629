import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { InputField } from "../InputField";
import { useEffect, useState } from "react";
import { isValidEmail, showToast } from "../../Utility";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "../../Redux/Services/AuthServices";
import OtpInput from "react-otp-input";
import { ResetPassword } from "./ResetPassword";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
};

export const OtpVerify = (props) => {
  const { response, setModalType, modalType, code, setCode } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state?.authReducer);

  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [forget, setForget] = useState(false);

  const [countDown, setCountDown] = useState(180);

  const emailValid = isValidEmail(email);

  const handleSubmit = async (event) => {
    setValidated(true);
    event.preventDefault();
    //   event.stopPropagation();
    if (code?.length === 6) {
      setValidated(false);

      const body = {
        user_email: response?.user_email,
        otp_code: code,
        user_id: response?.user_id,
      };

      try {
        let response = await dispatch(forgetPassword(body));
        if (response?.status === 200) {
          setCode("");
          setModalType("reset");
        }
      } catch (error) {
        if (error?.data?.message) {
          //   showError(error?.data?.message || "");
        }
      }
    } else {
      setValidated(true);
    }
  };
  const handleResendOtp = async (event) => {
    event.preventDefault();
    // event.stopPropagation();
    let body = {
      user_email: response?.user_email,
    };
    try {
      let response = await dispatch(forgetPassword(body));
      if (response?.status === 200) {
        // showToast("OTP Resend successfull");
        setCountDown(59);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const secondsToTime =(e)=>{
    const  
          m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
          s = Math.floor(e % 60).toString().padStart(2,'0');
    console.log(m + ':' + s)
    return  m + ':' + s;
    //return `${h}:${m}:${s}`;
}

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  const handleForgetPassword = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      if (!email) {
        showToast("Please Enter Email", "error");
      } else {
        var body = {
          user_email: email.trim(),
        };
        let res = await dispatch(forgetPassword(body));
        if (res?.status == 200) {
          //   setError(false);
        }
      }
    }
  };

  return (
    <>
      <div className="passwordform Loginform">
        <div className="signUp" onClick={() => setForget(false)}>
          If you have an account? <a>Sign in</a>
        </div>
        {/* <Form noValidate validated={validated} onSubmit={handleForgetPassword}> */}
        <OtpInput
          value={code}
          onChange={setCode}
          numInputs={6}
          renderInput={(props) => <input {...props} />}
          inputStyle={otpStyle}
          inputType="tel"
        />
        {validated && code?.length !== 6 ? (
          <h6 className="err_msg">This field is required</h6>
        ) : (
          ""
        )}

        <Button onClick={handleSubmit} variant="primary vrfyOtp" type="submit">
          Verify OTP
        </Button>

        {countDown === 0 ? (
          <div
            sx={{
              textAlign: "center",
              pt: 2,
            }}
          >
            <p
              className="anchor_link"
              sx={{ cursor: "pointer" }}
              onClick={handleResendOtp}
            >
              Resend Otp
            </p>
          </div>
        ) : (
          <div>
            <p
              // className="anchor_link"
              sx={{
                color: "grey",
                pt: 2,
                display: "flex",
                justifyContent: "center",
              }}
            >
              The verification code will expire in{" "}
              <p style={{ margin: 0, color: "#ce011f", marginLeft: 6 }}>
                {secondsToTime(countDown)} 
              </p>
            </p>
          </div>
        )}
      </div>
    </>
  );
};
